* {
    /*font-family: 'Nanum Myeongjo', serif !important;*/
    font-family: 'source-han-serif-korean', serif !important;
}

i {
    font-family: Icons !important;
}

.hanSerifJapanese {
    font-family: source-han-serif-japanese, serif !important;
    font-style: normal;
}

.ui.card > :first-child, .ui.cards > .card > :first-child {
    border-radius: 0 !important;
}

.noMargin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.essayMainBody > p, .essayMainBody {
    line-height: 2.2rem !important;
}

@media only screen and (max-width: 767px) {
    .ui.container.noMargin {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ui.container > .ui.stackable.grid > .column, .ui.container > .ui.stackable.grid > .row > .column.headerGrid {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    .ui.card > :first-child, .ui.cards > .card > :first-child {
        border-radius: 0 !important;
    }
}
